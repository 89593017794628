@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;600&display=swap');
body{
  font-family: 'Raleway', sans-serif;
  font-size: 16px;
}

*{
  margin: 0;
  padding: 0;
}


:root{
  --color-primary: #009245;
  --color-primary-variant: #007d41;
  --color-nav: #d9d9d9;
  --color-nav-variant: #e6e6e6;
  --color-secondary: #f5f5f5;
  --color-accent: #d6cf09;
  --transition-duration: .3s;
}