

/* button css start */
.outlined-button{
    cursor: pointer;
    padding: 10px;
    color: var(--color-primary);
    background: none;
    background-color: none;
    border: 1px solid var(--color-primary);
    transition: var(--transition-duration) ease;
    border-radius: 5px;
}

.outlined-button:hover{
    color: white;
    background: var(--color-primary);
}
/* button css end */

/*page  */
.page-body{
 padding-top: 60px;  
}
.row{
    display: flex;
    flex-wrap: wrap;
}

.align-center{
    align-items: center;
}
.space-evenly{
    justify-content: space-evenly;
}
.space-around{
    justify-content: space-around;
}

.d-flex{
    display: flex;
    align-items: center;
}

.flex-wrap{
    flex-wrap: wrap;
}

.flex-1{
    flex: 1;
}

.col-lg-6{
    flex: 0 0 auto;
    width: 45%;
}

.col-lg-9{
    flex: 0 0 auto;
    width: 66%;
}

.col-lg-3{
    flex: 0 0 auto;
    width: 33%;
}

.gap-10{
    gap: 10px;
}
.gap-30{
    gap: 30px;
}

.fs-large{
    font-size: 3rem;
}
.f-uppercase{
    text-transform: uppercase;
}

.page-container{
    padding-left: 30px;
    padding-right: 30px;
}

.mt-30{
    margin-top: 30px;
}
.mt-40{
    margin-top: 40px;
}
.mt-10{
    margin-top: 10px;
}
.mb-10{
    margin-bottom: 10px;
}
.p-10{
    padding: 10px;
}
.pb-30{
    padding-bottom: 30px;
}

.underline{
    border-top: 1px solid gray;
}

.mx-60{
    margin-left: 60px !important;
    margin-right: 60px !important;
}

.text-white{
    color: white;
}

.text-center{
    text-align: center;
 }
 .center{
     display: flex;
     justify-content: center;
     align-items: center;
 }


@media (max-width: 850px){
    .row{
        display: block;
    }
    .flex-1{
        margin-top: 20px;
    }
    .col-lg-6{
        width: 100%;
    }
    .col-lg-3{
        width: 100%;
    }
    .col-lg-9{
        width: 100%;
    }
    .page-container{
        padding-left: 10px;
        padding-right: 10px;
    }

    .google-play{
        width: 6.92rem !important;
    height: 2rem !important;
    }
  

}


.google-play{
    width: 10.38rem;
    height: 3rem;
    cursor: pointer;
}

.heading{
    font-size: 1.1rem;
    text-transform: uppercase;
    font-weight: 600;

}

.bg-primary{
    background-image: linear-gradient(to right, var(--color-primary-variant), var(--color-primary));

}

/*end page*/

