.fixed-circle{
    width: 50px;
    height: 50px;
    border-radius: 100px;
    display: none;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
    position: fixed;
    bottom: 0;
    right: 0;
}

.bg-black{
    background-color: black;

}

.mb-10{
    margin-bottom: 10px;
}
.mr-10{
    margin-right: 10px;
}

.mb-70{
    margin-bottom: 70px;
}


.fixed-circle-icon{
    color: white;
    font-size: 1.5rem;
}


/* media queries */
@media (max-width: 850px){
    .fixed-circle{
        display: flex;
    }
}
/* end media queries */
