.navbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-image: linear-gradient(to right, var(--color-nav-variant), var(--color-nav));
    padding: 10px 30px 10px 30px;
    position: fixed;
    width: 100%;
    z-index: 30;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
}

.navbar-brand{  
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    
}

.navbar-title{
    color: black;
    font-size: 1rem;
}

.navbar-logo{
    width: 100px;
    height: auto;
    transition: .3s ease;
}

.navbar-logo:hover{
    width: 110px;
    height: auto;
}


.navbar-button{
    text-transform: uppercase;
    background-color: none;
    border: none;
    border-radius: 5px;
    background: var(--color-secondary);
    color: black;
    cursor: pointer;
    padding: 10px;
    transition: var(--transition-duration) ease;
}

.navbar-button:hover{
    transform: translateY(-3px);
    background: var(--color-accent);
}

.navbar-button-no-bg:hover{
    transform: translateY(-3px);
}

.navbar-button-no-bg{
    text-decoration: none;
    text-transform: uppercase;
    background-color: none;
    border: none;
    border-radius: 5px;
    background: none;
    color: black;
    font-size: 1rem;
    cursor: pointer;
    padding: 10px;
    margin-right: 30px;
    transition: var(--transition-duration) ease;
}
.navbar-button-no-bg-icon{
    margin-right: 10px;
    color: black;
}


.navbar-button-icon{
    margin-right: 10px;
    color: var(--color-primary);
}

.navbar-end{
    display: flex;
    align-items: center;
    gap: 10px;
}



/* media queries */
@media (max-width: 850px){
    .navbar-title{
        display: none;
    }
    .navbar{
        padding-left: 15px;
        padding-right: 15px;
    }
    .navbar-button-no-bg{
        display: none;
    }
    .navbar-button{
        margin-right: 20px;
    }
}
/* end media queries */