
.step-number{
    font-size: 4rem;
    font-weight: 600;
    color: gray;    
}

.step-items > h4{
    font-size: 1rem;
}

.step-items > h6{
    font-size: .8rem;
}

.step-items > p{
    color: gray;
    font-size: .8rem;
    margin-top: 5px;
}
.bg-home{
    background-image: linear-gradient(to bottom, var(--color-nav-variant), var(--color-nav));

}


.what-we-do-img-container{
    width: 100px;
    height: 100px;
    border: 1px solid white;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.what-we-do-container:hover .what-we-do-icon{
    width: 75px;
    fill: var(--color-accent);
}

.what-we-offer-headings{
    transition: var(--transition-duration) ease;
}

.what-we-do-container:hover .what-we-offer-headings{
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);

    transform: scale(1.2);
}

.border-black{
    border: 1px solid black;
}


.what-we-do-icon{
    width: 65px;
    height: auto;
   fill: white;
   transition: var(--transition-duration) ease;
}

.width-flex{
    width: 22%;
}


@media (max-width:850px) {
  .width-flex{
      width: 45%;
  }
}

@media (min-width:850px){
    .video-container{
        width: 60%;
    }
}


.video-container {
    overflow: hidden;
    position: relative;
    width:100%;
}

.video-container::after {
    padding-top: 56.25%;
    display: block;
    content: '';
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
  